import React, { lazy, Suspense, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'components/Common/Errors/ErrorBoundary/ErrorBoundary';
import CustomerRoute from 'components/CustomerRoute/CustomerRoute';
import { ScrollToTop } from 'components/Common/Scroll/ScrollToTop/ScrollToTop';
import useCurrentCountry from 'hooks/useCurrentCountry';
import useEnv from 'hooks/useEnv';
import AccountLayout from 'layout/AccountLayout/AccountLayout';
import MainLayout from 'layout/MainLayout';
import PageLayout from 'layout/PageLayout/PageLayout';
import CartProvider from 'providers/CartProvider';
import FlashMessageProvider from 'providers/FlashMessageProvider';
import HistoryProvider from 'providers/HistoryProvider';
import UserProvider from 'providers/UserProvider';
import { getRoutesUri, getUri } from 'services/generic';
import { isEnable as isicomEnable } from 'services/isicom';
import { IS_ENABLE as IS_VEESUAL_ENABLE } from 'services/mix-and-match';
import { BOT_PATTERN } from 'settings/bot';

// Import layouts with lazy
const CheckoutLayout = lazy(
  () => import('layout/CheckoutLayout/CheckoutLayout')
);

// Import pages with lazy
const Cart = lazy(() => import('app/checkout/cart/page'));
const CheckoutLogin = lazy(() => import('app/checkout/account/login/page'));
const CheckoutCreate = lazy(() => import('app/checkout/account/create/page'));
const Login = React.lazy(() => import('app/customer/account/login/page'));
const Payment = lazy(() => import('app/checkout/payment/page'));
const ProductListing = lazy(() => import('app/listing/page'));
const Shipping = lazy(() => import('app/checkout/shipping/page'));
const SignUp = React.lazy(() => import('app/customer/account/create/page'));
const ProductPage = lazy(() => import('app/catalog/product/page'));

function Router(): JSX.Element {
  const { urlCustomer } = useEnv();
  useEffect(() => {
    const regex = new RegExp(BOT_PATTERN, 'i');
    const userAgent = navigator.userAgent.toLowerCase();
    if (regex.test(userAgent)) {
      document.getElementById('didomi-notice-agree-button')?.click();
    }
  }, [document.getElementById('didomi-notice-agree-button')]);

  const isIsicomEnable = isicomEnable();
  const { isUk } = useCurrentCountry();
  const { t } = useTranslation();

  const HomePage = React.lazy(() => import('../components/HomePage/HomePage'));
  const Isicom = React.lazy(() => import('../components/Isicom/Isicom'));
  const LoginAdmin = React.lazy(
    () => import('../components/Login/LoginAdmin/LoginPage')
  );
  const CatalogRequest = React.lazy(
    () => import('app/customer/account/catalogrequest/page')
  );
  const CatalogRequestPage = React.lazy(
    () => import('../components/CatalogRequestPage/CatalogRequest')
  );
  const AccountInformations = React.lazy(
    () => import('app/customer/account/informations/page')
  );
  const PersonalDetails = React.lazy(
    () => import('app/customer/account/informations/edit/page')
  );
  const ChangeEmail = React.lazy(
    () => import('app/customer/account/informations/edit/email/page')
  );
  const ChangePassword = React.lazy(
    () => import('app/customer/account/informations/edit/password/page')
  );
  const EditDeliveryAddress = React.lazy(
    () => import('app/customer/account/informations/address/main/edit/page')
  );
  const EditSecondaryAddress = React.lazy(
    () =>
      import('app/customer/account/informations/address/secondary/edit/page')
  );

  const MyOrder = React.lazy(
    () => import('app/customer/account/order/history/page')
  );
  const Order = React.lazy(
    () => import('app/customer/account/order/details/page')
  );
  const Evt = React.lazy(
    () => import('app/customer/account/order/evt_details/page')
  );
  const ManageSubscription = React.lazy(
    () => import('app/customer/account/newsletter/page')
  );
  const AccountLogOut = React.lazy(
    () => import('app/customer/account/logoutSuccess/page')
  );
  const SearchPage = React.lazy(
    () => import('../components/SearchPage/SearchPage')
  );
  const ConfirmationPage = React.lazy(
    () => import('../components/ConfirmationPage/ConfirmationPage')
  );
  const DirectOrderPage = React.lazy(
    () => import('../components/DirectOrderPage/DirectOrderPage')
  );
  const ResetPassword = React.lazy(
    () => import('../components/ResetPassword/ResetPassword')
  );
  const CmsPage = React.lazy(() => import('../components/CmsPage/CmsPage'));
  const ContactPage = React.lazy(
    () => import('../components/ContactPage/ContactPage')
  );
  const NewsletterPage = React.lazy(
    () => import('../components/NewsletterPage/NewsletterPage')
  );
  const NewsletterUnsubscribePage = React.lazy(
    () =>
      import(
        '../components/NewsletterUnsubscribePage/NewsletterUnsubscribePage'
      )
  );
  const SitePlanProducts = React.lazy(
    () => import('app/site-plan-products/page')
  );
  const SitePlanCategories = React.lazy(
    () => import('app/site-plan-categories/page')
  );

  const listPathMainLayoutV2: RouteType[] = [
    {
      path: getUri('/contact'),
      element: <ContactPage />,
    },
    {
      path: getUri('*'),
      element: <CmsPage />,
      classes: {
        container: 'cms-page',
      },
    },
    {
      path: getRoutesUri('productSitemap') as string,
      element: <SitePlanProducts />,
    },
    {
      path: getRoutesUri('categorySitemap') as string,
      element: <SitePlanCategories />,
    },
  ];

  const listPathMainLayout: RouteType[] = [
    {
      path: getUri(`/P-:productId-:productSlug/*`),
      element: <ProductPage />,
    },
    {
      path: getUri(`/C-:categoryId-:categoryName/*`),
      element: <ProductListing />,
    },
    {
      path: getUri(`/Search/:search`),
      element: <SearchPage />,
    },
    {
      path: getUri(`/Recherche/:search`),
      element: <SearchPage />,
    },
    {
      path: getUri(`/checkout/cart`),
      element: <Cart />,
    },
    {
      path: getUri(`/checkout/confirmation`),
      element: <ConfirmationPage />,
    },
    {
      path: getRoutesUri<string>('directOrder'),
      element: <DirectOrderPage />,
    },
    {
      title: t('LABEL_MYACCOUNT_CATALOG'),
      path: getUri('/catalogrequest/'),
      element: <CatalogRequestPage />,
    },
    {
      path: getUri(`/`),
      element: <HomePage />,
    },
    {
      path: getRoutesUri('customerAccountLogOutSuccess') as string,
      element: <AccountLogOut />,
    },
  ];

  const listPathAccountNotLoggedLayoutV2: RouteType[] = [
    {
      path: getUri(`/newsletter/subscriber`),
      element: <NewsletterPage />,
      redirectPath: getRoutesUri('customerAccountNewsletter') as string,
    },
    {
      path: getUri(`/newsletter/subscriber/unsubscribe`),
      element: <NewsletterUnsubscribePage />,
      redirectPath: getRoutesUri('customerAccountNewsletter') as string,
    },
  ];

  const listPathAccountNotLoggedLayout: RouteType[] = [
    {
      path: getRoutesUri('customerLogin') as string,
      element: <Login />,
    },
    {
      path: getUri(`${urlCustomer}/loginAdmin`),
      element: <LoginAdmin />,
    },
    {
      path: getRoutesUri('customerSignup') as string,
      element: <SignUp />,
    },
    {
      path: getRoutesUri('customerForgotPasswordVerification') as string,
      element: <ResetPassword />,
    },
  ];

  const listPathAccountLoggedLayout: RouteType[] = [
    {
      path: getRoutesUri('customerAccountInformation') as string,
      element: <AccountInformations />,
    },
    {
      path: getRoutesUri('customerAccountInformationEdit') as string,
      element: <PersonalDetails />,
    },
    {
      path: getRoutesUri('customerAccountInformationEditEmail') as string,
      element: <ChangeEmail />,
    },
    {
      path: getRoutesUri('customerAccountInformationEditPassword') as string,
      element: <ChangePassword />,
    },
    {
      path: getRoutesUri('customerAccountAddressMainEdit') as string,
      element: <EditDeliveryAddress />,
    },
    {
      path: getRoutesUri('customerAccountAddressSecondaryEdit') as string,
      redirectOverseas: true,
      element: <EditSecondaryAddress />,
    },
    {
      path: getRoutesUri('customerAccountOrderHistory') as string,
      element: <MyOrder />,
    },
    {
      path: getRoutesUri('customerAccountNewsletter') as string,
      element: <ManageSubscription />,
    },
    {
      path: getRoutesUri('customerAccountOrderDetails') as string,
      element: <Order />,
    },
    {
      title: t('LABEL_ORDER_NUMBER_ORDER'),
      path: getRoutesUri('customerAccountOrderEvtDetails') as string,
      element: <Evt />,
    },
    {
      path: getRoutesUri('customerAccountCatalogRequest') as string,
      element: <CatalogRequest />,
    },
  ];

  if (IS_VEESUAL_ENABLE) {
    const MixAndMatch = React.lazy(() => import('app/mix-and-match/page'));

    listPathMainLayoutV2.push({
      path: getUri('/mix-and-match'),
      element: <MixAndMatch />,
    });
  }

  if (isUk) {
    const PersonalAccount = React.lazy(
      () => import('app/customer/account/my-personal-account/page')
    );

    listPathAccountLoggedLayout.push({
      path: getRoutesUri('customerAccountPersonalAccount') as string,
      element: <PersonalAccount />,
    });
  }

  const checkoutRoutes: RouteType[] = [
    {
      path: getRoutesUri<string>('checkoutLogin'),
      element: <CheckoutLogin />,
      layoutProps: {
        withSummary: false,
        authenticate: {
          must: false,
          redirect: getRoutesUri<string>('customerAccountInformation'),
        },
      },
    },
    {
      path: getRoutesUri<string>('checkoutSignup'),
      element: <CheckoutCreate />,
      layoutProps: {
        withSummary: false,
        authenticate: {
          must: false,
          redirect: getRoutesUri<string>('customerAccountInformation'),
        },
      },
    },
    {
      path: getRoutesUri<string>('checkoutShipping'),
      element: <Shipping />,
      layoutProps: {
        authenticate: {
          must: true,
          redirect: getRoutesUri<string>('checkoutLogin'),
        },
      },
    },
    {
      path: getUri(`/checkout/payment`),
      element: <Payment />,
      layoutProps: {
        authenticate: {
          must: true,
          redirect: getRoutesUri<string>('checkoutLogin'),
        },
      },
    },
  ];

  return (
    <BrowserRouter>
      <HistoryProvider>
        <UserProvider>
          <CookiesProvider>
            <CartProvider>
              <FlashMessageProvider>
                <ScrollToTop />
                <ErrorBoundary>
                  <Suspense fallback={<div />}>
                    {isIsicomEnable && <Isicom />}
                    <div id="suspense-child">
                      <Routes>
                        {listPathMainLayoutV2.map((route: RouteType) => {
                          const { path, element, classes } = route;

                          return (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <PageLayout classes={classes}>
                                  {element}
                                </PageLayout>
                              }
                            />
                          );
                        })}
                        {listPathMainLayout.map((route: RouteType) => {
                          const { path, element, emptyLayout } = route;

                          return (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <MainLayout emptyLayout={emptyLayout}>
                                  {element}
                                </MainLayout>
                              }
                            />
                          );
                        })}
                        {listPathAccountLoggedLayout.map((route: RouteType) => {
                          const { path, element, redirectOverseas } = route;

                          return (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <CustomerRoute
                                  accessNotLogged={false}
                                  redirectOverseas={redirectOverseas}
                                >
                                  <MainLayout arianeCurrentPath={path}>
                                    <AccountLayout>{element}</AccountLayout>
                                  </MainLayout>
                                </CustomerRoute>
                              }
                            />
                          );
                        })}
                        {listPathAccountNotLoggedLayoutV2.map(
                          (route: RouteType) => {
                            const { path, element, redirectPath } = route;

                            return (
                              <Route
                                key={path}
                                path={path}
                                element={
                                  <CustomerRoute
                                    accessNotLogged={true}
                                    redirectPath={redirectPath}
                                  >
                                    <PageLayout>{element}</PageLayout>
                                  </CustomerRoute>
                                }
                              />
                            );
                          }
                        )}
                        {listPathAccountNotLoggedLayout.map(
                          (route: RouteType) => {
                            const { path, element } = route;

                            return (
                              <Route
                                key={path}
                                path={path}
                                element={
                                  <CustomerRoute accessNotLogged={true}>
                                    <MainLayout arianeCurrentPath={path}>
                                      {element}
                                    </MainLayout>
                                  </CustomerRoute>
                                }
                              />
                            );
                          }
                        )}
                        {checkoutRoutes.map(route => {
                          const { path, element, layoutProps } = route;

                          return (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <CheckoutLayout {...layoutProps}>
                                  {element}
                                </CheckoutLayout>
                              }
                            />
                          );
                        })}
                      </Routes>
                    </div>
                  </Suspense>
                </ErrorBoundary>
              </FlashMessageProvider>
            </CartProvider>
          </CookiesProvider>
        </UserProvider>
      </HistoryProvider>
    </BrowserRouter>
  );
}

export default Router;

type RouteType = {
  title?: string;
  path: string;
  element: JSX.Element;
  emptyLayout?: boolean;
  redirectPath?: string;
  redirectOverseas?: boolean;
  classes?: { container?: string };
  layoutProps?: Record<string, unknown>;
};
