/**
 * Helper to get product information
 */
import {
  ProductType,
  ProductVariantType,
  SelectedProductAttributes,
} from 'types/ProductPageV2/Products';

type ProductAttributes = SelectedProductAttributes;

export const DEFAULT_AFIBEL_SEPARATOR_CHARACTER = '¤';

export function getCategory1(productAttributes: ProductAttributes): string {
  return productAttributes.codeLibelleFamille.Value;
}

export function getCategory2(productAttributes: ProductAttributes): string {
  return productAttributes.codeLibelleSsfamille.Value;
}

export function getCategory3(productAttributes: ProductAttributes): string {
  return productAttributes.subuniverse.Value;
}

export function getColor(productAttributes: ProductAttributes): string {
  return productAttributes.couleur.Value;
}

export function discountPercentage(
  productAttributes: ProductAttributes
): string {
  return productAttributes.decote.Value;
}

export function getDiscountPrice(productAttributes: ProductAttributes): string {
  return productAttributes.prixBarre.Value;
}

export function getName(productAttributes: ProductAttributes): string {
  return productAttributes.libelle.Value;
}

export function getModelName(productAttributes: ProductAttributes): string {
  return productAttributes.nomModele.Value.toUpperCase();
}

export function getFullName(productAttributes: ProductAttributes): string {
  return `${getName(productAttributes)} ${getModelName(
    productAttributes
  )} ${getColor(productAttributes)}`;
}

export function getDescription(productAttributes: ProductAttributes): string {
  return productAttributes.description.Value;
}

export function getCatalogSku(productAttributes: ProductAttributes): string {
  return productAttributes.catalogueSKU.Value;
}

export function getRealSku(productAttributes: ProductAttributes): string {
  return productAttributes.SKUReelle.Value;
}

export function getEan(productAttributes: ProductAttributes): string {
  return productAttributes.EAN?.Value || '';
}

export function getUniqPrice(
  productAttributes: ProductAttributes
): string | undefined {
  return productAttributes.prixUnique?.Value;
}

export function getUrl(
  product: ProductType | ProductVariantType,
  updateCallback?: (url: string) => string
): string {
  return updateCallback ? updateCallback(product.Url) : product.Url;
}

export function getFinalPrice(productAttributes: ProductAttributes): number {
  return +productAttributes.prix.Value;
}

export function getProductAvailabilityStatus(
  productAttributes: ProductAttributes
): string {
  return productAttributes.disponibilite.Value;
}

export function getBrandName(productAttributes: ProductAttributes): string {
  return productAttributes.metaMarque?.Value || '';
}

export function getSizeLabel(productAttributes: ProductAttributes): string {
  return productAttributes.codeTaille.Value;
}

export function getCup(
  productAttributes: ProductAttributes
): string | undefined {
  return productAttributes.bonnet?.Value;
}

export function getStandardImages(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): Array<string> {
  const standardOtherImages = getSplitImages(
    productAttributes.imgAutresSlim.Value || ''
  );

  if (standardOtherImages.length > 0) {
    return standardOtherImages.map(path =>
      updateCallback ? updateCallback(path) : path
    );
  }

  const standardMainImage = productAttributes.imgFaceSlim?.Value;

  return (standardMainImage ? [standardMainImage] : []).map(path =>
    updateCallback ? updateCallback(path) : path
  );
}

export function getStandardVideo(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.vidSlim?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getStandardVideoVignette(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.imgVignetteSlim?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getCurvyImages(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): Array<string> {
  const curvyOtherImages = getSplitImages(
    productAttributes.imgAutresCurvy?.Value || ''
  );

  if (curvyOtherImages.length > 0) {
    return curvyOtherImages.map(path =>
      updateCallback ? updateCallback(path) : path
    );
  }

  const curvyMainImage = productAttributes.imgFaceCurvy?.Value;

  return (curvyMainImage ? [curvyMainImage] : []).map(path =>
    updateCallback ? updateCallback(path) : path
  );
}

export function getCurvyVideo(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.vidCurvy?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getCurvyVideoVignette(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.imgVignetteCurvy?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getIdCustColor(productAttributes: ProductAttributes): string {
  return productAttributes.idCustColor.Value;
}

export function getSizeGuide(productAttributes: ProductAttributes): number {
  return parseInt(productAttributes.sizeGuide.Value || '0');
}

export function getStickerOpeco(productAttributes: ProductAttributes): string {
  return productAttributes.stickerOpeco.Value;
}

export function getMainSticker(productAttributes: ProductAttributes): string {
  return productAttributes.stickerPrincipal.Value;
}

export function getSubSticker(productAttributes: ProductAttributes): string {
  return productAttributes.stickerSecondaire1?.Value;
}

export function getSubSticker2(productAttributes: ProductAttributes): string {
  return productAttributes.stickerSecondaire2?.Value;
}

export function getVisuelBack(productAttributes: ProductAttributes): string {
  return productAttributes.imgDosPivot.Value;
}

export function getVisuelFace(productAttributes: ProductAttributes): string {
  return productAttributes.imgFacePivot.Value;
}

export function getLot(productAttributes: ProductAttributes): string {
  return productAttributes.metaLot.Value;
}

/**
 * Split image string by separator
 *
 * @param data
 * @param separator
 *
 * @return string[]
 */
function getSplitImages(
  data: string,
  separator = DEFAULT_AFIBEL_SEPARATOR_CHARACTER
): Array<string> {
  return data.split(separator).filter(Boolean);
}

export function getFullSubSticker(
  productAttributes: ProductAttributes
): string | undefined {
  const subSticker = getSubSticker(productAttributes);
  const subSticker2 = getSubSticker2(productAttributes);

  if (!subSticker) return undefined;

  return subSticker + (subSticker2 && ' - ' + subSticker2);
}

export function extractProductSku(path: string): string {
  const startIndex = path.indexOf('P-') + 2;
  const endIndex = path.indexOf('-', startIndex);

  return path.substring(startIndex, endIndex).substring(0, 7);
}
