import { CartBodyRequest } from 'providers/CartProvider';
import { getDataFromAttributes } from 'services/data';
import {
  getBrand,
  getCategory1,
  getCategory2,
  getCategory3,
  getCommercialOperationSticker,
  getCoupon,
  getDiscountAti,
  getDiscountHT,
  getName,
  getPriceAtiAfter,
  getPriceAtiOriginal,
  getPriceHTAfter,
  getPriceHTOriginal,
  getProductColorModel,
  getProductModel,
} from 'services/gtm/gtmProductAttributes';
import { PRODUCT_HT_RATIO } from 'settings/productV2';
import {
  GTM_PAGE_HOME,
  GTM_PAGE_LIST,
  GTM_PAGE_PRODUCT,
  GTM_SEARCH_CATEGORY_REDIRECTION,
  GTM_SEARCH_PRODUCT_REDIRECTION,
  GTM_SEARCH_WITH_RESULT,
  GTM_SEARCH_WITHOUT_RESULT,
} from 'settings/gtm/gtmPageView';
import {
  GTM_DATA_KEYS,
  GTM_DATA_MAPPING,
  GTM_PRODUCTS_KEY_NAME,
} from 'settings/gtm/gtmPageViewMapping';
import {
  CATEGORY_PAGE_REGEX,
  HOME_PAGE_REGEX,
  PRODUCT_PAGE_REGEX,
  SEARCH_PAGE_REGEX,
} from 'settings/regex';
import { AppliedFilter } from 'types/Controls/NextFilters';
import {
  FormattedProductType,
  GtmPageFilterType,
  GtmProductType,
} from 'types/gtm/GtmPageView';
import { BreadCrumb } from 'types/Controls/BreadCrumb';
import { AcpProduct, AcpProductSkus } from 'types/Acp';
import { ProductDataDetail, ProductDataListing } from 'types/Product';
import {
  ProductVariantsAttributesType,
  SelectedProductAttributes,
} from 'types/ProductPageV2/Products';
import { CartOfferedGift, CartProduct, CartSpecialOffer } from 'types/Cart';
import { ProductDetail } from 'types/Controls/ProductSheet';
import { FormattedFlowAttributeType } from 'types/formatter';

export function getInitialData(): Record<string, undefined> {
  return GTM_DATA_KEYS.reduce((acc, key) => {
    acc[key] = undefined;
    return acc;
  }, {});
}

export function getPageFilters(
  appliedFilters: AppliedFilter[]
): GtmPageFilterType[] {
  return appliedFilters.map(appliedFilter => {
    const { PartialUrl: partialUrl, Label: label } = appliedFilter;
    const filter_type = partialUrl.split('~')[0].split('-')[2];
    const filter_value = label;

    return { filter_type, filter_value };
  });
}

export function getPageCategories(breadCrumb: BreadCrumb): string[] {
  return breadCrumb.Hierarchies.map(hierarchie => {
    return hierarchie.Label;
  });
}

export function decimalRound(price: number): number {
  return Math.round(price * 100) / 100;
}

export function getHTPrice(price: number): number {
  return decimalRound(price * PRODUCT_HT_RATIO);
}

export function getSearchStatus(
  totalHits: number,
  products: AcpProduct[]
): string {
  let status = GTM_SEARCH_WITHOUT_RESULT;

  if (totalHits == 1) {
    const productUrl = products[0].skus[0].url;

    if (productUrl.includes('C-')) {
      status = GTM_SEARCH_CATEGORY_REDIRECTION;
    } else if (productUrl.includes('P-')) {
      status = GTM_SEARCH_PRODUCT_REDIRECTION;
    }
  } else if (totalHits > 1) {
    status = GTM_SEARCH_WITH_RESULT;
  }

  return status;
}

export function formatProductList(
  products: ProductDetail[],
  attributes: FormattedFlowAttributeType
): { [mainName: string]: string }[] {
  return products.map(product =>
    getDataFromAttributes(product.Attributes, attributes)
  );
}

export function formatProduct(
  productAttributes:
    | ProductDataListing
    | ProductDataDetail
    | { [mainName: string]: string }
): FormattedProductType {
  const {
    codelibellefamille,
    codelibellessfamille,
    couleur,
    disponibilite,
    libelle,
    libelletaille,
    metamarque,
    prix,
    prixbarre,
    skureelle,
    stickeropeco,
    subuniverse,
  } = productAttributes;

  return {
    codeLibelleFamille: codelibellefamille,
    codeLibelleSsfamille: codelibellessfamille,
    couleur: couleur,
    disponibilite: disponibilite,
    libelle: libelle,
    libelleTaille: libelletaille,
    metaMarque: metamarque,
    prix: prix,
    prixBarre: prixbarre,
    SKUReelle: skureelle,
    stickerOpeco: stickeropeco,
    subuniverse: subuniverse,
  };
}

export function formatProductV2(
  productAttributes: SelectedProductAttributes | ProductVariantsAttributesType
): FormattedProductType {
  const {
    codeLibelleFamille,
    codeLibelleSsfamille,
    couleur,
    disponibilite,
    libelle,
    libelleTaille,
    metaMarque,
    prix,
    prixBarre,
    SKUReelle,
    stickerOpeco,
    subuniverse,
  } = productAttributes;

  return {
    codeLibelleFamille: codeLibelleFamille?.Value,
    codeLibelleSsfamille: codeLibelleSsfamille?.Value,
    couleur: couleur?.Value,
    disponibilite: disponibilite?.Value,
    libelle: libelle?.Value,
    libelleTaille: libelleTaille?.Value,
    metaMarque: metaMarque?.Value,
    prix: prix?.Value,
    prixBarre: prixBarre?.Value,
    SKUReelle: SKUReelle?.Value,
    stickerOpeco: stickerOpeco?.Value,
    subuniverse: subuniverse?.Value,
  };
}

// @TODO - Transform this function to commun function for all products (merch, productsheet...)
export function formatMerchandisingProductData(
  attributes: Record<string, unknown>
): Record<string, unknown> {
  const {
    brand,
    category1,
    category2,
    category3,
    commercialOperationSticker,
    crossedOutPrice,
    name,
    price,
    sku,
  } = attributes;
  const formattedPrice = formatPrice(price);
  const formattedCrossedOutPrice = formatPrice(crossedOutPrice);
  const priceHTOriginal = getPriceHTOriginal(
    formattedPrice,
    formattedCrossedOutPrice
  );
  const priceAtiOriginal = getPriceAtiOriginal(
    formattedPrice,
    formattedCrossedOutPrice
  );
  const priceHTAfter = getPriceHTAfter(formattedPrice);
  const priceAtiAfter = getPriceAtiAfter(formattedPrice);

  return {
    r: getProductModel(sku),
    rc: getProductColorModel(sku),
    rct: sku,
    name: getName(name),
    brand: getBrand(brand),
    category_1: getCategory1(category1),
    category_2: getCategory2(category2),
    category_3: getCategory3(category3),
    price_tf_original: priceHTOriginal,
    price_ati_original: priceAtiOriginal,
    price_tf_after_discount: priceHTAfter,
    price_ati_after_discount: priceAtiAfter,
    discount_ati: getDiscountAti(priceAtiOriginal, priceAtiOriginal),
    discount_tf: getDiscountHT(priceHTOriginal, priceHTAfter),
    coupon: getCoupon(),
    sticker: getCommercialOperationSticker(commercialOperationSticker),
  };
}

export function formatCartProducts(
  products: CartProduct[]
): FormattedProductType[] {
  return products.map(product => formatCartProduct(product));
}

export function formatCartProduct(
  productAttributes: CartProduct
): FormattedProductType {
  if (productAttributes.infoOctipas) {
    return {
      ...formatProductV2(productAttributes.infoOctipas),
      quantity: productAttributes.quantity,
    };
  }

  const {
    color,
    crossedOutPrice,
    line,
    modelName,
    price,
    quantity,
    size,
    sku,
    stock,
  } = productAttributes;

  return {
    couleur: color,
    disponibilite: stock,
    libelle: modelName,
    libelleTaille: size,
    line: line,
    prix: price,
    prixBarre: crossedOutPrice,
    quantity: quantity,
    SKUReelle: sku,
  };
}

export function formatCartOsG(
  osgs: CartOfferedGift[] | CartSpecialOffer[]
): FormattedProductType[] {
  return osgs.flatMap(osg =>
    osg.products.map(({ sku, stock, sizeLabel, price, choice }) => ({
      disponibilite: stock,
      libelle: choice,
      libelleTaille: sizeLabel,
      prix: price,
      quantity: 1,
      SKUReelle: sku,
    }))
  );
}

export function formatCartBodyRequestProduct(
  product: CartBodyRequest
): FormattedProductType {
  const { sku, qty, price, preDiscountPrice, stock, sizeLabel } = product;

  return {
    prix: price,
    prixBarre: preDiscountPrice,
    quantity: parseInt(qty),
    SKUReelle: sku,
    disponibilite: stock,
    libelleTaille: sizeLabel,
  };
}

export function formatAcpProduct(
  product: AcpProductSkus
): FormattedProductType {
  const { Prix, PrixBarre, shortLabel, skuId } = product;

  return {
    libelle: shortLabel?.toLowerCase(),
    prix: Prix,
    prixBarre: PrixBarre,
    SKUReelle: skuId,
  };
}

export function cleanData(
  pageType: string,
  data: Record<string, unknown>
): Record<string, unknown> {
  const unusedKeys = ([] as string[]).concat(
    ...Object.keys(GTM_DATA_MAPPING[pageType]).map(key => {
      return GTM_DATA_MAPPING[pageType][key];
    })
  );

  const set = new Set(['', null, ...unusedKeys]);
  const entries = Object.entries(data);

  for (const [key, value] of entries) {
    if (set.has(value) || set.has(key)) {
      data[key] = undefined;
    }
  }

  // Rename products key depending on page
  if (
    GTM_PRODUCTS_KEY_NAME[pageType] !== undefined &&
    data.hasOwnProperty('products')
  ) {
    data[GTM_PRODUCTS_KEY_NAME[pageType]] = data['products'];
    delete data['products'];
  }

  return data;
}

export function getProductAccess(previousUrl: string | undefined): string {
  const previousPage = previousUrl ?? document.referrer;
  const internalUrl = previousUrl?.includes(window.location.host);
  let pageData = '';

  switch (true) {
    case internalUrl && RegExp(CATEGORY_PAGE_REGEX).test(previousPage):
      pageData = GTM_PAGE_LIST;
      break;
    case internalUrl && RegExp(PRODUCT_PAGE_REGEX).test(previousPage):
      pageData = GTM_PAGE_PRODUCT;
      break;
    case internalUrl && RegExp(SEARCH_PAGE_REGEX).test(previousPage):
      pageData = `/search/${previousPage.split('/').slice(-1)}`;
      break;
    case internalUrl && RegExp(HOME_PAGE_REGEX).test(previousPage):
      pageData = GTM_PAGE_HOME;
      break;
    case internalUrl:
      pageData = previousPage;
      break;
    default:
      pageData = 'site entry : %source/medium%';
      break;
  }

  return pageData;
}

export function formatProductQuantityChange(
  product: GtmProductType,
  quantity: number,
  line: number
): GtmProductType {
  const added = quantity > 0;
  const updatedGtmProduct = { ...product };

  return {
    ...updatedGtmProduct,
    ...{
      add_to_cart_type: added ? 'panier' : undefined,
      remove_from_cart_type: !added ? 'panier' : undefined,
      quantity: Math.abs(quantity),
      line: line,
    },
  };
}

function formatPrice(price: string): number | undefined {
  return price !== '0' && price !== '' ? parseFloat(price) : undefined;
}
